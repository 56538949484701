import {
  SET_ITEM_LIST,
  SET_LOADING_ITEMS,
  UPDATE_ITEM_LIST,
} from "../actions/ItemAction";

const initialState = {
  isLoadingItemList: true,
  items: [],
};

export default function ItemReducer(state = initialState, action) {
  switch (action.type) {
    case SET_LOADING_ITEMS:
      return {
        ...state,
        isLoadingItemList: action.payload,
      };
    case SET_ITEM_LIST:
      return {
        ...state,
        items: action.payload,
      };
    case UPDATE_ITEM_LIST:
      return {
        ...state,
        items: state.items.concat(action.payload),
      };
    default:
      return state;
  }
}
