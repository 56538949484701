//CONTENT LOADER
import ContentLoader from "react-content-loader";

const MyLoader = () => (
  <div className="row">
    <div className="col-md-2 col-sm-2 py-4">
      <div className="px-3 mb-5">
        <ContentLoader type="facebook">
          <rect x="0" y="0" rx="5" ry="5" width="70" height="70" />
          <rect x="100" y="17" rx="4" ry="4" width="300" height="40" />
        </ContentLoader>
      </div>
      <div className="px-3">
        <ContentLoader type="facebook">
          <rect x="0" y="0" rx="4" ry="4" width="100%" height="70" />
        </ContentLoader>
        <ContentLoader type="facebook">
          <rect x="0" y="0" rx="4" ry="4" width="100%" height="70" />
        </ContentLoader>
        <ContentLoader type="facebook">
          <rect x="0" y="0" rx="4" ry="4" width="100%" height="70" />
        </ContentLoader>
        <ContentLoader type="facebook">
          <rect x="0" y="0" rx="4" ry="4" width="100%" height="70" />
        </ContentLoader>
        <ContentLoader type="facebook">
          <rect x="0" y="0" rx="4" ry="4" width="100%" height="70" />
        </ContentLoader>
      </div>
    </div>
    <div
      className="col-md-10 col-sm-10 py-4"
      style={{
        backgroundColor: "#fafbfd",
        paddingLeft: 30,
        paddingRight: 30,
        height: "100vh",
      }}>
      <div className="row">
        <div className="col-md-5 col-sm-5">
          <ContentLoader type="facebook">
            <rect x="0" y="3" rx="1" ry="1" width="150" height="25" />
          </ContentLoader>
        </div>
        <div className="col-md-7 col-sm-7">
          <div className="row">
            <div className="col-md-8 col-sm-8">
              <ContentLoader type="facebook">
                <rect x="0" y="1" rx="1" ry="1" width="90%" height="27" />
              </ContentLoader>
            </div>
            <div className="col-md-4 col-sm-4" style={{ paddingRight: 30 }}>
              <ContentLoader type="facebook">
                <rect x="0" y="1" rx="1" ry="1" width="100%" height="65" />
              </ContentLoader>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default MyLoader;
