import server from "../../config/server";

export const SET_AREA_LIST = "SET_AREA_LIST";
export const SET_LOADING_AREA_LIST = "SET_LOADING_AREA_LIST";
export const UPDATE_AREA_LIST = "UPDATE_AREA_LIST";

export function getAreaList() {
  return (dispatch) => {
    dispatch({
      type: SET_LOADING_AREA_LIST,
      payload: true,
    });
    server({
      method: "get",
      url: `/areas`,
      headers: {
        access_token: localStorage.getItem("BAS_access_token"),
      },
    })
      .then(({ data }) => {
        dispatch({
          type: SET_AREA_LIST,
          payload: data,
        });
        dispatch({
          type: SET_LOADING_AREA_LIST,
          payload: false,
        });
      })
      .catch(({ response }) => {
        console.log(response.data.message);
        dispatch({
          type: SET_LOADING_AREA_LIST,
          payload: false,
        });
      });
  };
}

export function addArea(payload) {
  return (dispatch) => {
    return server({
      method: "post",
      url: "/areas",
      headers: {
        access_token: localStorage.getItem("BAS_access_token"),
      },
      data: payload,
    });
  };
}
