import React, { memo, Suspense, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import MyLoader from "../components/MyLoader";
import {
  CLEAR_ACCOUNT,
  getAccountDetail,
  SET_ACCOUNT,
} from "../store/actions/AccountAction";

const MainLayoutNotLogged = memo(({ Container, title }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    const token = localStorage.getItem("BAS_access_token");
    if (token) {
      dispatch(getAccountDetail(token))
        .then(({ data }) => {
          // console.log(data);
          dispatch({
            type: SET_ACCOUNT,
            payload: data,
          });
          history.push("/");
        })
        .catch((error) => {
          dispatch({
            type: CLEAR_ACCOUNT,
          });
        });
    }
  }, [dispatch, history]);
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Suspense fallback={<MyLoader />}>
        <Container />
      </Suspense>
    </>
  );
});

export default MainLayoutNotLogged;
