import {
  SET_LOADING_USAGE_HISTORIES,
  SET_USAGE_HISTORIES,
} from "../actions/UsageHistoryAction";

const initialState = {
  isLoadingUsageHistories: true,
  usage_histories: [],
};

export default function UsageHistoryReducer(state = initialState, action) {
  switch (action.type) {
    case SET_LOADING_USAGE_HISTORIES:
      return {
        ...state,
        isLoadingUsageHistories: action.payload,
      };
    case SET_USAGE_HISTORIES:
      return {
        ...state,
        usage_histories: action.payload,
      };
    default:
      return state;
  }
}
