import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import {
  AccountReducer,
  NavbarReducer,
  InventoryReducer,
  ItemReducer,
  BrandReducer,
  LocationReducer,
  OutboundReducer,
  AreaReducer,
  InboxReducer,
  ReturnReducer,
  InboundReducer,
  UsageHistoryReducer,
} from "./reducers";

const reducers = combineReducers({
  account: AccountReducer,
  navbar: NavbarReducer,
  inventories: InventoryReducer,
  items: ItemReducer,
  brands: BrandReducer,
  locations: LocationReducer,
  outbounds: OutboundReducer,
  areas: AreaReducer,
  inboxes: InboxReducer,
  retur_items: ReturnReducer,
  inbounds: InboundReducer,
  usage_histories: UsageHistoryReducer,
});

const middlewares = applyMiddleware(thunk);

const store = createStore(reducers, middlewares);

export default store;
