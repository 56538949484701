import server from "../../config/server";

export const SET_BRAND_LIST = "SET_BRAND_LIST";
export const SET_LOADING_BRAND_LIST = "SET_LOADING_BRAND_LIST";
export const UPDATE_BRAND_LIST = "UPDATE_BRAND_LIST";

export function getBrandList() {
  return (dispatch) => {
    dispatch({
      type: SET_LOADING_BRAND_LIST,
      payload: true,
    });
    server({
      method: "get",
      url: "/brands",
      headers: {
        access_token: localStorage.getItem("BAS_access_token"),
      },
    })
      .then(({ data }) => {
        dispatch({
          type: SET_BRAND_LIST,
          payload: data,
        });
        dispatch({
          type: SET_LOADING_BRAND_LIST,
          payload: false,
        });
      })
      .catch(({ response }) => {
        console.log(response.data.message);
        dispatch({
          type: SET_LOADING_BRAND_LIST,
          payload: false,
        });
      });
  };
}
