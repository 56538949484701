import { SET_NAV_COLLAPSE } from "../actions/NavbarAction";

const initialState = {
  isCollapse: true,
};

export default function NavbarReducer(state = initialState, action) {
  switch (action.type) {
    case SET_NAV_COLLAPSE:
      return {
        ...state,
        isCollapse: !state.isCollapse,
      };
    default:
      return state;
  }
}
