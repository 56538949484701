import server from "../../config/server";

export const SET_OUTBOUND_LIST = "SET_OUTBOUND_LIST";
export const SET_LOADING_OUTBOUND_LIST = "SET_LOADING_OUTBOUND_LIST";
export const UPDATE_OUTBOUND_LIST = "UPDATE_OUTBOUND_LIST";
export const SET_LOADING_OUTBOUND_DETAIL = "SET_LOADING_OUTBOUND_DETAIL";
export const SET_OUTBOUND_DETAIL = "SET_OUTBOUND_DETAIL";
export const DELETE_OUTBOUND = "DELETE_OUTBOUND";

export function getOutBoundList(date, sortBy = "createdAt", order = "DESC") {
  return (dispatch) => {
    dispatch({
      type: SET_LOADING_OUTBOUND_LIST,
      payload: true,
    });
    server({
      method: "get",
      url: `/outbounds?out_date=${date}&sortBy=${sortBy}&order=${order}`,
      headers: {
        access_token: localStorage.getItem("BAS_access_token"),
      },
    })
      .then(({ data }) => {
        dispatch({
          type: SET_OUTBOUND_LIST,
          payload: data,
        });
        dispatch({
          type: SET_LOADING_OUTBOUND_LIST,
          payload: false,
        });
      })
      .catch(({ response }) => {
        console.log(response.data.message);
        dispatch({
          type: SET_LOADING_OUTBOUND_LIST,
          payload: false,
        });
      });
  };
}

export function getOutBoundDetail(id) {
  return (dispatch) => {
    dispatch({
      type: SET_LOADING_OUTBOUND_DETAIL,
      payload: true,
    });
    server({
      method: "get",
      url: `/outbounds/${id}`,
      headers: {
        access_token: localStorage.getItem("BAS_access_token"),
      },
    })
      .then(({ data }) => {
        dispatch({
          type: SET_OUTBOUND_DETAIL,
          payload: data,
        });
        dispatch({
          type: SET_LOADING_OUTBOUND_DETAIL,
          payload: false,
        });
      })
      .catch(({ response }) => {
        console.log(response.data.message);
        dispatch({
          type: SET_LOADING_OUTBOUND_DETAIL,
          payload: false,
        });
      });
  };
}

export function createOutbound(payload) {
  return (dispatch) => {
    return server({
      method: "post",
      url: "/outbounds",
      headers: {
        access_token: localStorage.getItem("BAS_access_token"),
      },
      data: payload,
    });
  };
}

export function deleteOutbound(id) {
  return (dispatch) => {
    return server({
      method: "delete",
      url: `/outbounds/${id}`,
      headers: {
        access_token: localStorage.getItem("BAS_access_token"),
      },
    });
  };
}
