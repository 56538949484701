import {
  SET_LOADING_LOGIN,
  SET_ACCOUNT,
  CLEAR_ACCOUNT,
  SET_LOADING_ACCOUNT_LIST,
  SET_ACCOUNT_LIST,
  ADD_NEW_ACCOUNT,
  DELETE_ACCOUNT,
} from "../actions/AccountAction";

const initialState = {
  isLoading: false,
  isLoadingAccountList: true,
  account_list: [],
  current_account: null,
};

export default function AccountReducer(state = initialState, action) {
  switch (action.type) {
    case SET_LOADING_LOGIN:
      return {
        ...state,
        isLoading: action.payload,
      };
    case SET_LOADING_ACCOUNT_LIST:
      return {
        ...state,
        isLoadingAccountList: action.payload,
      };
    case SET_ACCOUNT:
      return {
        ...state,
        current_account: action.payload,
      };
    case CLEAR_ACCOUNT:
      return {
        ...state,
        current_account: null,
      };
    case SET_ACCOUNT_LIST:
      return {
        ...state,
        account_list: action.payload,
      };
    case ADD_NEW_ACCOUNT:
      return {
        ...state,
        account_list: state.account_list.concat(action.payload),
      };
    case DELETE_ACCOUNT:
      return {
        ...state,
        account_list: state.account_list.filter(
          (el) => el.id !== action.payload
        ),
      };
    default:
      return state;
  }
}
