import { lazy } from "react";

const Login = lazy(() => import("../containers/auth/Login"));
const ForgotPassword = lazy(() => import("../containers/auth/ForgotPassword"));
const Dashboard = lazy(() => import("../containers/dashboard/Dashboard"));

//INVENTORIES
const Inventories = lazy(() => import("../containers/inventories/Inventories"));
const InventoryIn = lazy(() => import("../containers/inventories/InventoryIn"));
const InventoryOut = lazy(() =>
  import("../containers/inventories/InventoryOut")
);

//ITEMS
const ItemList = lazy(() => import("../containers/items/ItemList"));

//LOCATIONS
const LocationList = lazy(() => import("../containers/locations/LocationList"));
const LocationDetail = lazy(() =>
  import("../containers/locations/LocationDetail")
);

//INBOX
const InboxList = lazy(() => import("../containers/inboxes/InboxList"));
const InboxDetail = lazy(() => import("../containers/inboxes/InboxDetail"));

//TRANSACTIONS
const OutboundList = lazy(() =>
  import("../containers/transactions/outbound/OutboundList")
);
const OutboundDetail = lazy(() =>
  import("../containers/transactions/outbound/OutboundDetail")
);
const OutboundForm = lazy(() =>
  import("../containers/transactions/outbound/OutboundForm")
);
const ReturList = lazy(() =>
  import("../containers/transactions/retur/ReturList")
);
const ReturDetail = lazy(() =>
  import("../containers/transactions/retur/ReturDetail")
);
const ReturForm = lazy(() =>
  import("../containers/transactions/retur/ReturForm")
);
const InboundList = lazy(() =>
  import("../containers/transactions/inbound/InboundList")
);
const InboundForm = lazy(() =>
  import("../containers/transactions/inbound/InboundForm")
);

//ADMIN MANAGEMENT
const AdminList = lazy(() => import("../containers/admin/AdminList"));
const AdminProfile = lazy(() => import("../containers/admin/AdminProfile"));

//USAGE HISTORIES
const UsageList = lazy(() => import("../containers/usage-histories/UsageList"));
const UsageForm = lazy(() => import("../containers/usage-histories/UsageForm"));

//USAGE HISTORIES
const StockOpnameList = lazy(() =>
  import("../containers/stock-opname/StockOpnameList")
);

//LOG LIST
const LogList = lazy(() => import("../containers/logs/LogList"));
const LogDetail = lazy(() => import("../containers/logs/LogDetail"));

const routes = [
  {
    title: "Login",
    container: Login,
    exact: true,
    path: "/login",
    logged: false,
  },
  {
    title: "Forget Password",
    container: ForgotPassword,
    exact: true,
    path: "/lupa-password",
    logged: false,
  },
  {
    title: "Dashboard",
    container: Dashboard,
    exact: true,
    path: "/",
    logged: true,
  },
  {
    title: "Daftar Stok",
    container: Inventories,
    exact: true,
    path: "/stok",
    logged: true,
  },
  {
    title: "Form Penerimaan Barang",
    container: InventoryIn,
    exact: true,
    path: "/stok/barang-masuk",
    logged: true,
  },
  {
    title: "Form Pengeluaran Barang",
    container: InventoryOut,
    exact: true,
    path: "/stok/barang-keluar",
    logged: true,
  },
  {
    title: "Daftar Barang",
    container: ItemList,
    exact: true,
    path: "/daftar-barang",
    logged: true,
  },
  {
    title: "Daftar Lokasi",
    container: LocationList,
    exact: true,
    path: "/daftar-lokasi",
    logged: true,
  },
  {
    title: "Detail Stok",
    container: LocationDetail,
    exact: true,
    path: "/daftar-lokasi/:locationId",
    logged: true,
  },
  {
    title: "Notifikasi",
    container: InboxList,
    exact: true,
    path: "/notifikasi",
    logged: true,
  },
  {
    title: "Notifikasi",
    container: InboxDetail,
    exact: true,
    path: "/notifikasi/:id",
    logged: true,
  },
  {
    title: "Transaksi",
    container: OutboundList,
    exact: true,
    path: "/transaksi/barang-keluar",
    logged: true,
  },
  {
    title: "Detail",
    container: OutboundDetail,
    exact: true,
    path: "/transaksi/barang-keluar/detail/:outboundId",
    logged: true,
  },
  {
    title: "Form Pengeluaran Barang",
    container: OutboundForm,
    exact: true,
    path: "/transaksi/barang-keluar/buat-baru",
    logged: true,
  },
  {
    title: "Transaksi",
    container: ReturList,
    exact: true,
    path: "/transaksi/barang-retur",
    logged: true,
  },
  {
    title: "Detail",
    container: ReturDetail,
    exact: true,
    path: "/transaksi/barang-retur/detail/:returId",
    logged: true,
  },
  {
    title: "Form Pengembalian Barang",
    container: ReturForm,
    exact: true,
    path: "/transaksi/barang-retur/buat-baru",
    logged: true,
  },
  {
    title: "Transaksi",
    container: InboundList,
    exact: true,
    path: "/transaksi/barang-masuk",
    logged: true,
  },
  {
    title: "Form Penerimaan Barang",
    container: InboundForm,
    exact: true,
    path: "/transaksi/barang-masuk/buat-baru",
    logged: true,
  },
  {
    title: "Manajemen Admin",
    container: AdminList,
    exact: true,
    path: "/manajemen-admin",
    logged: true,
  },
  {
    title: "Profile",
    container: AdminProfile,
    exact: true,
    path: "/profile",
    logged: true,
  },
  {
    title: "Riwayat Pemakaian",
    container: UsageList,
    exact: true,
    path: "/riwayat-pemakaian",
    logged: true,
  },
  {
    title: "Form Pemakaian",
    container: UsageForm,
    exact: true,
    path: "/riwayat-pemakaian/buat-baru",
    logged: true,
  },
  {
    title: "Stock Opname",
    container: StockOpnameList,
    exact: true,
    path: "/stock-opname",
    logged: true,
  },
  {
    title: "Logs",
    container: LogList,
    exact: true,
    path: "/logs",
    logged: true,
  },
  {
    title: "Logs Detail",
    container: LogDetail,
    exact: true,
    path: "/logs/:logId",
    logged: true,
  },
];

export default routes;
