import {
  SET_LOCATION_LIST,
  SET_LOADING_LOCATION_LIST,
  UPDATE_LOCATION_LIST,
  SET_LOADING_LOCATION_DETAIL,
  SET_LOCATION_DETAIL,
} from "../actions/LocationAction";

const initialState = {
  isLoadingLocationList: true,
  isLoadingLocationDetail: true,
  locations: [],
  details: null,
};

export default function LocationReducer(state = initialState, action) {
  switch (action.type) {
    case SET_LOADING_LOCATION_LIST:
      return {
        ...state,
        isLoadingLocationList: action.payload,
      };
    case SET_LOADING_LOCATION_DETAIL:
      return {
        ...state,
        isLoadingLocationDetail: action.payload,
      };
    case SET_LOCATION_LIST:
      return {
        ...state,
        locations: action.payload,
      };
    case SET_LOCATION_DETAIL:
      return {
        ...state,
        details: action.payload,
      };
    case UPDATE_LOCATION_LIST:
      return {
        ...state,
        locations: state.locations.concat(action.payload),
      };
    default:
      return state;
  }
}
