import {
  SET_AREA_LIST,
  SET_LOADING_AREA_LIST,
  UPDATE_AREA_LIST,
} from "../actions/AreaAction";

const initialState = {
  isLoadingAreaList: true,
  areas: [],
};

export default function LocationReducer(state = initialState, action) {
  switch (action.type) {
    case SET_LOADING_AREA_LIST:
      return {
        ...state,
        isLoadingAreaList: action.payload,
      };
    case SET_AREA_LIST:
      return {
        ...state,
        areas: action.payload,
      };
    case UPDATE_AREA_LIST:
      return {
        ...state,
        areas: state.areas.concat(action.payload),
      };
    default:
      return state;
  }
}
