import server from "../../config/server";

export const SET_LOADING_LOGIN = "SET_LOADING_LOGIN";
export const SET_ACCOUNT = "SET_ACCOUNT";
export const CLEAR_ACCOUNT = "CLEAR_ACCOUNT";
export const SET_ACCOUNT_LIST = "SET_ACCOUNT_LIST";
export const SET_LOADING_ACCOUNT_LIST = "SET_LOADING_ACCOUNT_LIST";
export const ADD_NEW_ACCOUNT = "ADD_NEW_ACCOUNT";
export const DELETE_ACCOUNT = "DELETE_ACCOUNT";

export function login(payload) {
  return (dispatch) => {
    dispatch({
      type: SET_LOADING_LOGIN,
      payload: true,
    });
    return server({
      method: "post",
      url: "/login",
      data: payload,
    });
  };
}

export function register(payload) {
  return (dispatch) => {
    return server({
      method: "post",
      url: "/register",
      data: payload,
    });
  };
}

export function getAccountDetail(access_token) {
  return (dispatch) => {
    return server({
      method: "get",
      url: "/account-detail",
      headers: {
        access_token,
      },
    });
  };
}

export function getAccountList() {
  return (dispatch) => {
    dispatch({
      type: SET_LOADING_ACCOUNT_LIST,
      payload: true,
    });
    server({
      method: "get",
      url: `/admins`,
      headers: {
        access_token: localStorage.getItem("BAS_access_token"),
      },
    })
      .then(({ data }) => {
        dispatch({
          type: SET_ACCOUNT_LIST,
          payload: data,
        });
        dispatch({
          type: SET_LOADING_ACCOUNT_LIST,
          payload: false,
        });
      })
      .catch(({ response }) => {
        console.log(response.data.message);
        dispatch({
          type: SET_LOADING_ACCOUNT_LIST,
          payload: false,
        });
      });
  };
}

export function deleteAccount(id) {
  return (dispatch) => {
    return server({
      method: "delete",
      url: `/admins/${id}`,
      headers: {
        access_token: localStorage.getItem("BAS_access_token"),
      },
    });
  };
}

export function editAccount(payload) {
  return (dispatch) => {
    return server({
      method: "put",
      url: `/admins/${payload.id}`,
      headers: {
        access_token: localStorage.getItem("BAS_access_token"),
      },
      data: payload,
    });
  };
}

export function resetPassword(payload) {
  return (dispatch) => {
    return server({
      method: "put",
      url: `/reset-password`,
      data: payload,
    });
  };
}

export function forgotPassword(payload) {
  return (dispatch) => {
    return server({
      method: "post",
      url: `/forgot-password`,
      data: payload,
    });
  };
}

export function verifyCode(payload) {
  return (dispatch) => {
    return server({
      method: "post",
      url: `/verify-code`,
      data: payload,
    });
  };
}
