import server from "../../config/server";

export const SET_RETUR_ITEM_LIST = "SET_RETUR_ITEM_LIST";
export const SET_LOADING_RETUR_LIST = "SET_LOADING_RETUR_LIST";
export const SET_LOADING_RETUR_DETAIL = "SET_LOADING_RETUR_DETAIL";
export const SET_RETUR_DETAIL = "SET_RETUR_DETAIL";
export const DELETE_RETUR = "DELETE_RETUR";

export function getReturList(date, sortBy = "createdAt", order = "DESC") {
  return (dispatch) => {
    dispatch({
      type: SET_LOADING_RETUR_LIST,
      payload: true,
    });
    server({
      method: "get",
      url: `/returns?retur_date=${date}&sortBy=${sortBy}&order=${order}`,
      headers: {
        access_token: localStorage.getItem("BAS_access_token"),
      },
    })
      .then(({ data }) => {
        dispatch({
          type: SET_RETUR_ITEM_LIST,
          payload: data,
        });
        dispatch({
          type: SET_LOADING_RETUR_LIST,
          payload: false,
        });
      })
      .catch(({ response }) => {
        console.log(response.data.message);
        dispatch({
          type: SET_LOADING_RETUR_LIST,
          payload: false,
        });
      });
  };
}

export function getReturDetail(id) {
  return (dispatch) => {
    dispatch({
      type: SET_LOADING_RETUR_DETAIL,
      payload: true,
    });
    server({
      method: "get",
      url: `/returns/${id}`,
      headers: {
        access_token: localStorage.getItem("BAS_access_token"),
      },
    })
      .then(({ data }) => {
        dispatch({
          type: SET_RETUR_DETAIL,
          payload: data,
        });
        dispatch({
          type: SET_LOADING_RETUR_DETAIL,
          payload: false,
        });
      })
      .catch(({ response }) => {
        console.log(response.data.message);
        dispatch({
          type: SET_LOADING_RETUR_DETAIL,
          payload: false,
        });
      });
  };
}

export function createRetur(payload) {
  return (dispatch) => {
    return server({
      method: "post",
      url: "/returns",
      headers: {
        access_token: localStorage.getItem("BAS_access_token"),
      },
      data: payload,
    });
  };
}

export function deleteRetur(id) {
  return (dispatch) => {
    return server({
      method: "delete",
      url: `/returns/${id}`,
      headers: {
        access_token: localStorage.getItem("BAS_access_token"),
      },
    });
  };
}
