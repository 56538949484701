import {
  SET_BRAND_LIST,
  SET_LOADING_BRAND_LIST,
  UPDATE_BRAND_LIST,
} from "../actions/BrandAction";

const initialState = {
  isLoadingBrandList: true,
  brands: [],
};

export default function ItemReducer(state = initialState, action) {
  switch (action.type) {
    case SET_LOADING_BRAND_LIST:
      return {
        ...state,
        isLoadingBrandList: action.payload,
      };
    case SET_BRAND_LIST:
      return {
        ...state,
        brands: action.payload,
      };
    case UPDATE_BRAND_LIST:
      return {
        ...state,
        brands: state.brands.concat(action.payload),
      };
    default:
      return state;
  }
}
