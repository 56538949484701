import {
  SET_INBOUND_LIST,
  SET_LOADING_INBOUND_LIST,
} from "../actions/InboundAction";

const initialState = {
  isLoadingInboundList: true,
  inbounds: [],
};

export default function InboundReducer(state = initialState, action) {
  switch (action.type) {
    case SET_LOADING_INBOUND_LIST:
      return {
        ...state,
        isLoadingInboundList: action.payload,
      };
    case SET_INBOUND_LIST:
      return {
        ...state,
        inbounds: action.payload,
      };
    default:
      return state;
  }
}
