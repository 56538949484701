import server from "../../config/server";

export const SET_INBOUND_LIST = "SET_INBOUND_LIST";
export const SET_LOADING_INBOUND_LIST = "SET_LOADING_INBOUND_LIST";

export function getInboundList(date, sortBy = "createdAt", order = "DESC") {
  return (dispatch) => {
    dispatch({
      type: SET_LOADING_INBOUND_LIST,
      payload: true,
    });
    server({
      method: "get",
      url: `/batches?recieved_date=${date}&sortBy=${sortBy}&order=${order}`,
      headers: {
        access_token: localStorage.getItem("BAS_access_token"),
      },
    })
      .then(({ data }) => {
        dispatch({
          type: SET_INBOUND_LIST,
          payload: data,
        });
        dispatch({
          type: SET_LOADING_INBOUND_LIST,
          payload: false,
        });
      })
      .catch(({ response }) => {
        console.log(response.data.message);
        dispatch({
          type: SET_LOADING_INBOUND_LIST,
          payload: false,
        });
      });
  };
}
