import React, { memo, Suspense, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import MyLoader from "../components/MyLoader";
import Content from "./sections/Content";
import NavbarLeft from "./sections/NavbarLeft";
import {
  CLEAR_ACCOUNT,
  getAccountDetail,
  SET_ACCOUNT,
} from "../store/actions/AccountAction";

const MainLayout = memo(({ Container, title }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    const token = localStorage.getItem("BAS_access_token");
    const name = localStorage.getItem("name");
    if (!token || !name) {
      history.push("/login");
    } else {
      dispatch(getAccountDetail(token))
        .then(({ data }) => {
          // console.log(data, "<<< detail account in MainLayout");
          dispatch({
            type: SET_ACCOUNT,
            payload: data,
          });
        })
        .catch((error) => {
          dispatch({
            type: CLEAR_ACCOUNT,
          });
          history.push("/login");
        });
    }
  }, [dispatch, history]);
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Suspense fallback={<MyLoader />}>
        <div
          className="d-flex flex-row"
          style={{ overflow: "auto", minHeight: "100vh" }}>
          <NavbarLeft />
          <Content Container={Container} title={title} />
        </div>
      </Suspense>
    </>
  );
});

export default MainLayout;
