import {
  SET_OUTBOUND_LIST,
  SET_LOADING_OUTBOUND_LIST,
  UPDATE_OUTBOUND_LIST,
  SET_LOADING_OUTBOUND_DETAIL,
  SET_OUTBOUND_DETAIL,
  DELETE_OUTBOUND,
} from "../actions/OutboundAction";

const initialState = {
  isLoadingOutboundList: true,
  outbounds: [],
  isLoadingOutboundDetail: true,
  details: null,
};

export default function OutboundReducer(state = initialState, action) {
  switch (action.type) {
    case SET_LOADING_OUTBOUND_LIST:
      return {
        ...state,
        isLoadingOutboundList: action.payload,
      };
    case SET_OUTBOUND_LIST:
      return {
        ...state,
        outbounds: action.payload,
      };
    case SET_LOADING_OUTBOUND_DETAIL:
      return {
        ...state,
        isLoadingOutboundDetail: action.payload,
      };
    case SET_OUTBOUND_DETAIL:
      return {
        ...state,
        details: action.payload,
      };
    case UPDATE_OUTBOUND_LIST:
      return {
        ...state,
        outbounds: state.outbounds.concat(action.payload),
      };
    case DELETE_OUTBOUND:
      return {
        ...state,
        outbounds: state.outbounds.filter((el) => el.id !== action.payload),
      };
    default:
      return state;
  }
}
