import server from "../../config/server";

export const SET_USAGE_HISTORIES = "SET_USAGE_HISTORIES";
export const SET_LOADING_USAGE_HISTORIES = "SET_LOADING_USAGE_HISTORIES";

export function getUsageHistories(sortBy, order, LocationId, periode) {
  return (dispatch) => {
    dispatch({
      type: SET_LOADING_USAGE_HISTORIES,
      payload: true,
    });
    server({
      method: "get",
      url: `/usagehistories?sortBy=${sortBy}&order=${order}&LocationId=${LocationId}&periode=${periode}`,
      headers: {
        access_token: localStorage.getItem("BAS_access_token"),
      },
    })
      .then(({ data }) => {
        dispatch({
          type: SET_USAGE_HISTORIES,
          payload: data,
        });
        dispatch({
          type: SET_LOADING_USAGE_HISTORIES,
          payload: false,
        });
      })
      .catch(({ response }) => {
        console.log(response.data.message);
        dispatch({
          type: SET_LOADING_USAGE_HISTORIES,
          payload: false,
        });
      });
  };
}

export function createUsage(data) {
  return (dispatch) => {
    return server({
      method: "post",
      url: `/usagehistories`,
      headers: {
        access_token: localStorage.getItem("BAS_access_token"),
      },
      data,
    });
  };
}

export function deleteUsage(id) {
  return (dispatch) => {
    return server({
      method: "delete",
      url: `/usagehistories/${id}`,
      headers: {
        access_token: localStorage.getItem("BAS_access_token"),
      },
    });
  };
}
