import server from "../../config/server";

export const SET_LOCATION_LIST = "SET_LOCATION_LIST";
export const SET_LOADING_LOCATION_LIST = "SET_LOADING_LOCATION_LIST";
export const UPDATE_LOCATION_LIST = "UPDATE_LOCATION_LIST";
export const SET_LOADING_LOCATION_DETAIL = "SET_LOADING_LOCATION_DETAIL";
export const SET_LOCATION_DETAIL = "SET_LOCATION_DETAIL";

export function getLocationList(locationId, areaId) {
  return (dispatch) => {
    dispatch({
      type: SET_LOADING_LOCATION_LIST,
      payload: true,
    });
    server({
      method: "get",
      url: `/locations?locationId=${locationId}&areaId=${areaId}`,
      headers: {
        access_token: localStorage.getItem("BAS_access_token"),
      },
    })
      .then(({ data }) => {
        dispatch({
          type: SET_LOCATION_LIST,
          payload: data,
        });
        dispatch({
          type: SET_LOADING_LOCATION_LIST,
          payload: false,
        });
      })
      .catch(({ response }) => {
        console.log(response.data.message);
        dispatch({
          type: SET_LOADING_LOCATION_LIST,
          payload: false,
        });
      });
  };
}

export function getLocationDetail(locationId) {
  return (dispatch) => {
    dispatch({
      type: SET_LOADING_LOCATION_DETAIL,
      payload: true,
    });
    server({
      method: "get",
      url: `/locations/${locationId}`,
      headers: {
        access_token: localStorage.getItem("BAS_access_token"),
      },
    })
      .then(({ data }) => {
        // console.log(data);
        dispatch({
          type: SET_LOCATION_DETAIL,
          payload: data,
        });
        dispatch({
          type: SET_LOADING_LOCATION_DETAIL,
          payload: false,
        });
      })
      .catch(({ response }) => {
        console.log(response.data.message);
        dispatch({
          type: SET_LOADING_LOCATION_DETAIL,
          payload: false,
        });
      });
  };
}

export function addLocation(payload) {
  return (dispatch) => {
    return server({
      method: "post",
      url: "/locations",
      headers: {
        access_token: localStorage.getItem("BAS_access_token"),
      },
      data: payload,
    });
  };
}

export function editLocation(payload) {
  return (dispatch) => {
    return server({
      method: "put",
      url: `/locations/${payload.id}`,
      headers: {
        access_token: localStorage.getItem("BAS_access_token"),
      },
      data: payload,
    });
  };
}
