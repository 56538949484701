import {
  SET_INVENTORIES,
  SET_LOADING_INVENTORIES,
  DELETE_INVENTORY,
  UPDATE_INVENTORY,
  UPDATE_LIST_INVENTORIES,
} from "../actions/InventoryAction";

const initialState = {
  isLoadingInventories: true,
  inventories: [],
};

export default function InventoryReducer(state = initialState, action) {
  switch (action.type) {
    case SET_LOADING_INVENTORIES:
      return {
        ...state,
        isLoadingInventories: action.payload,
      };
    case SET_INVENTORIES:
      return {
        ...state,
        inventories: action.payload,
      };
    case DELETE_INVENTORY:
      return {
        ...state,
        inventories: state.inventories.filter((el) => el.id !== action.payload),
      };
    case UPDATE_INVENTORY:
      let temp = state.inventories;
      for (let i = 0; i < temp.length; i++) {
        if (temp[i].id === action.payload.id) {
          temp[i] = action.payload;
          break;
        }
      }
      return {
        ...state,
        inventories: temp,
      };
    case UPDATE_LIST_INVENTORIES:
      return {
        ...state,
        inventories: action.payload,
      };
    default:
      return state;
  }
}
