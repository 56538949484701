import React, { memo } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const NavbarItem = memo(({ title, link, icon, collapseMenu, active }) => {
  return (
    <Link
      to={link}
      className={[
        "d-flex flex-row py-4 menu-item position-relative",
        active ? "active" : "",
      ].join(" ")}>
      <div
        className="d-flex align-items-center justify-content-center"
        style={{ width: 70 }}>
        <FontAwesomeIcon
          icon={icon}
          className={active ? "green" : "grey-muted"}
        />
      </div>
      {collapseMenu && (
        <div style={{ flex: 1 }}>
          <span className="grey-muted">{title}</span>
        </div>
      )}
    </Link>
  );
});

export default NavbarItem;
