import React, { memo, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getInboxList } from "../../store/actions/InboxAction";
import { CLEAR_ACCOUNT } from "../../store/actions/AccountAction";

const Content = memo(({ Container, title }) => {
  const { isLoadingInboxList, inboxes } = useSelector((state) => state.inboxes);
  const { current_account } = useSelector((state) => state.account);
  const history = useHistory();
  const dispatch = useDispatch();
  const [isInboxShown, setIsInboxShown] = useState(false);
  const [totalInbox, setTotalInbox] = useState(0);

  useEffect(() => {
    let isCanceled = false;

    if (!isCanceled && isLoadingInboxList) {
      dispatch(getInboxList());
    }

    return () => (isCanceled = true);
  }, [dispatch, isLoadingInboxList]);

  useEffect(() => {
    let count = 0;
    for (const el of inboxes) {
      if (!el.is_read) {
        count++;
      }
    }
    setTotalInbox(count);
  }, [inboxes]);

  return (
    <div
      className="py-5 px-4 d-flex flex-column border-standar"
      style={{
        flex: 1,
        backgroundColor: "#fafbfd",
        borderTopWidth: 0,
        borderRightWidth: 0,
      }}
    >
      <div className="d-flex flex-row justify-content-between mb-5">
        <h4 className="grey big-font bold-font">{title}</h4>
        <div className="position-relative">
          <div className="d-flex align-items-center">
            <span className="d-inline-block black medium-font semi-bold mr-2">
              Hi, {localStorage.getItem("name")} |
            </span>
            <span className="d-flex align-items-center mr-3">
              <FontAwesomeIcon
                icon={["fas", "user-circle"]}
                className="grey-muted title-font semi-bold cursor-pointer logout"
                onClick={() => {
                  history.push("/profile");
                }}
              />
            </span>
            {current_account &&
              (current_account.role === "staff" ? (
                <></>
              ) : (
                <span className="position-relative d-flex align-items-center mr-3">
                  <FontAwesomeIcon
                    icon={["fas", "envelope"]}
                    className="grey-muted title-font semi-bold cursor-pointer inbox"
                    onClick={() => {
                      setIsInboxShown(!isInboxShown);
                    }}
                  />
                  {totalInbox > 0 && (
                    <span
                      className="d-flex align-items-center justify-content-center position-absolute rounded-circle bg-danger white semi-bold"
                      style={{
                        top: -12,
                        right: -10,
                        zIndex: 10,
                        width: 17,
                        height: 17,
                        fontSize: 10,
                        padding: 10,
                        border: "2px solid #fff",
                      }}
                    >
                      {totalInbox}
                    </span>
                  )}
                </span>
              ))}
            <span className="d-flex align-items-center">
              <FontAwesomeIcon
                icon={["fas", "sign-out-alt"]}
                className="grey-muted title-font semi-bold cursor-pointer logout"
                onClick={() => {
                  localStorage.removeItem("BAS_access_token");
                  localStorage.removeItem("name");
                  dispatch({
                    type: CLEAR_ACCOUNT,
                  });
                  history.push("/login");
                }}
              />
            </span>
          </div>

          {isInboxShown && (
            <div
              // ref={ref}
              className="bg-white position-absolute border-standar standar-radius shadow"
              style={{
                top: 30,
                right: 0,
                zIndex: 10,
                width: 400,
                maxHeight: 300,
                overflowY: "auto",
              }}
            >
              {inboxes.length < 1 ? (
                <div className="p-2">Tidak ada notifikasi</div>
              ) : (
                <div className="normal-font black standar-radius cursor-pointer">
                  {inboxes.map(
                    ({ id, title, description, is_read, createdAt }, idx) => {
                      return (
                        <div
                          key={id}
                          className={"p-2 inbox-item "}
                          style={{
                            borderBottom: "1px solid #c6c6c6",
                            borderTopLeftRadius: idx === 0 ? 8 : 0,
                            borderTopRightRadius: idx === 0 ? 8 : 0,
                            borderBottomLeftRadius:
                              idx === inboxes.length - 1 ? 8 : 0,
                            borderBottomRightRadius:
                              idx === inboxes.length - 1 ? 8 : 0,
                            display: idx > 4 ? "none" : "block",
                          }}
                          onClick={() => {
                            history.push(`/notifikasi/${id}`);
                            setIsInboxShown(false);
                          }}
                        >
                          <div className="black bold-font d-flex align-items-center">
                            {title}
                            {!is_read && (
                              <div
                                className="bg-red rounded-circle ml-1"
                                style={{ width: 8, height: 8 }}
                              ></div>
                            )}
                          </div>
                          <div className="grey-muted">{description}</div>
                        </div>
                      );
                    }
                  )}
                  {inboxes.length > 5 && (
                    <div
                      className="text-center blue-link py-3 inbox-item"
                      onClick={() => {
                        history.push(`/notifikasi`);
                        setIsInboxShown(false);
                      }}
                    >
                      Lihat Semua
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <Container />
    </div>
  );
});

export default Content;
