import server from "../../config/server";

export const SET_INBOX_LIST = "SET_INBOX_LIST";
export const SET_LOADING_INBOX = "SET_LOADING_INBOX";
export const UPDATE_INBOX_LIST = "UPDATE_INBOX_LIST";
export const SET_INBOX_DETAIL = "SET_INBOX_DETAIL";
export const SET_LOADING_DETAIL = "SET_LOADING_DETAIL";

export function getInboxList() {
  return (dispatch) => {
    dispatch({
      type: SET_LOADING_INBOX,
      payload: true,
    });
    server({
      method: "get",
      url: "/notifications",
      headers: {
        access_token: localStorage.getItem("BAS_access_token"),
      },
    })
      .then(({ data }) => {
        dispatch({
          type: SET_INBOX_LIST,
          payload: data,
        });
        dispatch({
          type: SET_LOADING_INBOX,
          payload: false,
        });
      })
      .catch(({ response }) => {
        console.log(response.data.message);
        dispatch({
          type: SET_LOADING_INBOX,
          payload: false,
        });
      });
  };
}

export function getInboxDetail(id) {
  return (dispatch) => {
    dispatch({
      type: SET_LOADING_DETAIL,
      payload: true,
    });
    dispatch({
      type: SET_INBOX_DETAIL,
      payload: null,
    });
    server({
      method: "get",
      url: `/notifications/${id}`,
      headers: {
        access_token: localStorage.getItem("BAS_access_token"),
      },
    })
      .then(({ data }) => {
        dispatch({
          type: SET_INBOX_DETAIL,
          payload: {
            ...data,
            data: JSON.parse(data.data),
          },
        });
        dispatch({
          type: SET_LOADING_DETAIL,
          payload: false,
        });
      })
      .catch(({ response }) => {
        console.log(response.data.message);
        dispatch({
          type: SET_LOADING_DETAIL,
          payload: false,
        });
      });
  };
}

export function readInbox(id) {
  return (dispatch) => {
    server({
      method: "put",
      url: `/notifications/${id}`,
      headers: {
        access_token: localStorage.getItem("BAS_access_token"),
      },
    })
      .then(({ data }) => {
        dispatch({
          type: SET_INBOX_LIST,
          payload: data,
        });
      })
      .catch(({ response }) => {
        console.log(response.data.message);
      });
  };
}
