import axios from "axios";

// const url = "http://localhost:4000";
const url = "https://apitest.gudangbas.com/";

const server = axios.create({
  baseURL: url,
});

export default server;
