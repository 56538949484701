import server from "../../config/server";

export const SET_ITEM_LIST = "SET_ITEM_LIST";
export const SET_LOADING_ITEMS = "SET_LOADING_ITEMS";
export const UPDATE_ITEM_LIST = "UPDATE_ITEM_LIST";

export function getItemList() {
  return (dispatch) => {
    dispatch({
      type: SET_LOADING_ITEMS,
      payload: true,
    });
    server({
      method: "get",
      url: "/items",
      headers: {
        access_token: localStorage.getItem("BAS_access_token"),
      },
    })
      .then(({ data }) => {
        dispatch({
          type: SET_ITEM_LIST,
          payload: data,
        });
        dispatch({
          type: SET_LOADING_ITEMS,
          payload: false,
        });
      })
      .catch(({ response }) => {
        console.log(response.data.message);
        dispatch({
          type: SET_LOADING_ITEMS,
          payload: false,
        });
      });
  };
}

export function addItem(payload) {
  return (dispatch) => {
    return server({
      method: "post",
      url: "/items",
      headers: {
        access_token: localStorage.getItem("BAS_access_token"),
      },
      data: payload,
    });
  };
}

export function editItem(payload) {
  return (dispatch) => {
    return server({
      method: "put",
      url: `/items/${payload.id}`,
      headers: {
        access_token: localStorage.getItem("BAS_access_token"),
      },
      data: payload,
    });
  };
}
