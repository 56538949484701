import React, { Suspense } from "react";

//INITIALIZE PROVIDER
import { Provider } from "react-redux";
import store from "./store";

//INITIALIZE ROUTER
import { BrowserRouter, Route, Switch } from "react-router-dom";
import routes from "./routes";

import "pace-js";
import "pace-js/themes/green/pace-theme-minimal.css";

// CSS
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/general.scss";

//COMPONENTS
import MainLayout from "./layouts/MainLayout";
import MainLayoutNotLogged from "./layouts/MainLayoutNotLogged";

//FONTAWESOME ICON
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import {
  faEyeSlash,
  faEye,
  faSearch,
  faPen,
  faInfoCircle,
  faChevronUp,
  faChevronDown,
  faCircle,
  faCamera,
  faKeyboard,
  faVolumeUp,
  faVideo,
  faTrashAlt,
  faTrash,
  faArrowsAlt,
  faChevronRight,
  faUserCircle,
  faHome,
  faThLarge,
  faWarehouse,
  faBoxes,
  faBars,
  faChevronLeft,
  faPlusCircle,
  faTimes,
  faMapMarkerAlt,
  faExchangeAlt,
  faMapMarkedAlt,
  faEnvelope,
  faSignOutAlt,
  faCaretUp,
  faCaretDown,
  faArrowLeft,
  faHistory,
  faHands,
  faUsers,
  faFileExcel,
  faBarcode,
  faFile,
  faRandom,
  faMinusCircle
} from "@fortawesome/free-solid-svg-icons";
import MyLoader from "./components/MyLoader";

library.add(
  fab,
  faEyeSlash,
  faEye,
  faSearch,
  faInfoCircle,
  faPen,
  faChevronUp,
  faChevronDown,
  faCircle,
  faCamera,
  faKeyboard,
  faVolumeUp,
  faVideo,
  faTrashAlt,
  faTrash,
  faArrowsAlt,
  faChevronRight,
  faUserCircle,
  faHome,
  faThLarge,
  faWarehouse,
  faBoxes,
  faBars,
  faChevronRight,
  faChevronLeft,
  faPlusCircle,
  faTimes,
  faMapMarkerAlt,
  faExchangeAlt,
  faMapMarkedAlt,
  faEnvelope,
  faSignOutAlt,
  faCaretUp,
  faCaretDown,
  faArrowLeft,
  faHistory,
  faHands,
  faUsers,
  faFileExcel,
  faBarcode,
  faFile,
  faRandom,
  faMinusCircle
);

const loading = () => {
  return (
    <div className="lds-ring mt-2">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Switch>
          <Suspense fallback={loading()}>
            {routes.map(({ path, exact, container, title, logged }, i) => {
              return (
                <Route
                  key={i}
                  path={path}
                  exact={exact}
                  render={() => {
                    return logged ? (
                      <Suspense fallback={<MyLoader />}>
                        <MainLayout Container={container} title={title} />
                      </Suspense>
                    ) : (
                      <MainLayoutNotLogged
                        Container={container}
                        title={title}
                      />
                    );
                  }}
                />
              );
            })}
          </Suspense>
        </Switch>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
