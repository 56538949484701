import {
  SET_INBOX_LIST,
  SET_LOADING_INBOX,
  SET_LOADING_DETAIL,
  SET_INBOX_DETAIL,
} from "../actions/InboxAction";

const initialState = {
  isLoadingInboxList: true,
  inboxes: [],
  isLoadingDetail: true,
  inbox: null,
};

export default function ItemReducer(state = initialState, action) {
  switch (action.type) {
    case SET_LOADING_INBOX:
      return {
        ...state,
        isLoadingInboxList: action.payload,
      };
    case SET_LOADING_DETAIL:
      return {
        ...state,
        isLoadingDetail: action.payload,
      };
    case SET_INBOX_LIST:
      return {
        ...state,
        inboxes: action.payload,
      };
    case SET_INBOX_DETAIL:
      return {
        ...state,
        inbox: action.payload,
      };
    default:
      return state;
  }
}
