import React, { memo, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SET_NAV_COLLAPSE } from "../../store/actions/NavbarAction";
import Logo from "../../assets/images/bas-logo.png";
import NavbarItem from "./components/NavbarItem";

const NavbarLeft = memo(() => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { isCollapse } = useSelector((state) => state.navbar);
  const { current_account } = useSelector((state) => state.account);

  const splitHistory = history.location.pathname.split("/");

  const [navbars, setNavbars] = useState([
    {
      title: "Dashboard",
      link: "/",
      actived: "/",
      icon: ["fas", "th-large"],
    },
    {
      title: "Daftar Stok",
      link: "/stok",
      actived: "/stok",
      icon: ["fas", "warehouse"],
    },
    {
      title: "Daftar Lokasi",
      link: "/daftar-lokasi",
      actived: "/daftar-lokasi",
      icon: ["fas", "map-marked-alt"],
    },
    {
      title: "Stock Opname",
      link: "/stock-opname",
      actived: "/stock-opname",
      icon: ["fa", "file"],
    },
  ]);

  const onNavbarCollapse = () => {
    dispatch({ type: SET_NAV_COLLAPSE });
  };

  useEffect(() => {
    if (current_account) {
      if (current_account.role === "direktur") {
        setNavbars([
          {
            title: "Dashboard",
            link: "/",
            actived: "/",
            icon: ["fas", "th-large"],
          },
          {
            title: "Daftar Stok",
            link: "/stok",
            actived: "/stok",
            icon: ["fas", "warehouse"],
          },
          {
            title: "Transaksi",
            link: "/transaksi/barang-keluar",
            actived: "/transaksi",
            icon: ["fas", "hands"],
          },
          {
            title: "Riwayat Pemakaian",
            link: "/riwayat-pemakaian",
            actived: "/riwayat-pemakaian",
            icon: ["fas", "history"],
          },
          {
            title: "Daftar Barang",
            link: "/daftar-barang",
            actived: "/daftar-barang",
            icon: ["fas", "boxes"],
          },
          {
            title: "Daftar Lokasi",
            link: "/daftar-lokasi",
            actived: "/daftar-lokasi",
            icon: ["fas", "map-marked-alt"],
          },
          {
            title: "Notifikasi",
            link: "/notifikasi",
            actived: "/notifikasi",
            icon: ["fas", "envelope"],
          },
          {
            title: "Stock Opname",
            link: "/stock-opname",
            actived: "/stock-opname",
            icon: ["fa", "file"],
          },
          {
            title: "Logs",
            link: "/logs",
            actived: "/logs",
            icon: ["fa", "random"],
          },
          {
            title: "Manajemen Admin",
            link: "/manajemen-admin",
            actived: "/manajemen-admin",
            icon: ["fas", "users"],
          },
        ]);
      } else if (current_account.role === "admin") {
        setNavbars([
          {
            title: "Dashboard",
            link: "/",
            actived: "/",
            icon: ["fas", "th-large"],
          },
          {
            title: "Daftar Stok",
            link: "/stok",
            actived: "/stok",
            icon: ["fas", "warehouse"],
          },
          {
            title: "Transaksi",
            link: "/transaksi/barang-keluar",
            actived: "/transaksi",
            icon: ["fas", "hands"],
          },
          {
            title: "Riwayat Pemakaian",
            link: "/riwayat-pemakaian",
            actived: "/riwayat-pemakaian",
            icon: ["fas", "history"],
          },
          {
            title: "Daftar Barang",
            link: "/daftar-barang",
            actived: "/daftar-barang",
            icon: ["fas", "boxes"],
          },
          {
            title: "Daftar Lokasi",
            link: "/daftar-lokasi",
            actived: "/daftar-lokasi",
            icon: ["fas", "map-marked-alt"],
          },
          {
            title: "Notifikasi",
            link: "/notifikasi",
            actived: "/notifikasi",
            icon: ["fas", "envelope"],
          },
          {
            title: "Stock Opname",
            link: "/stock-opname",
            actived: "/stock-opname",
            icon: ["fa", "file"],
          },
          {
            title: "Logs",
            link: "/logs",
            actived: "/logs",
            icon: ["fa", "random"],
          },
        ]);
      }
    }
  }, [current_account]);
  return (
    <div
      className={["bg-white", isCollapse ? "" : "small-nav"].join(" ")}
      id="navbar-left"
      style={{ width: 300, overflowY: "auto" }}
    >
      <div className="d-flex flex-row py-4">
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ width: 70, height: 55 }}
        >
          <FontAwesomeIcon
            icon={["fas", "bars"]}
            className="cursor-pointer"
            style={{ marginBottom: 10 }}
            onClick={onNavbarCollapse}
          />
        </div>
        {isCollapse && (
          <div style={{ flex: 1 }}>
            <img alt="..." style={{ width: 120 }} src={Logo} />
          </div>
        )}
      </div>
      {navbars.map((data, idx) => (
        <NavbarItem
          active={`/${splitHistory[1]}` === data.actived}
          collapseMenu={isCollapse}
          key={idx}
          {...data}
        />
      ))}
    </div>
  );
});

export default NavbarLeft;
