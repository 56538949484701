import {
  SET_RETUR_ITEM_LIST,
  SET_LOADING_RETUR_LIST,
  SET_LOADING_RETUR_DETAIL,
  SET_RETUR_DETAIL,
  DELETE_RETUR,
} from "../actions/ReturnAction";

const initialState = {
  isLoadingReturList: true,
  retur_items: [],
  isLoadingReturDetail: true,
  details: null,
};

export default function ReturnReducer(state = initialState, action) {
  switch (action.type) {
    case SET_LOADING_RETUR_LIST:
      return {
        ...state,
        isLoadingReturList: action.payload,
      };
    case SET_RETUR_ITEM_LIST:
      return {
        ...state,
        retur_items: action.payload,
      };
    case SET_LOADING_RETUR_DETAIL:
      return {
        ...state,
        isLoadingReturDetail: action.payload,
      };
    case SET_RETUR_DETAIL:
      return {
        ...state,
        details: action.payload,
      };
    case DELETE_RETUR:
      return {
        ...state,
        retur_items: state.retur_items.filter((el) => el.id !== action.payload),
      };
    default:
      return state;
  }
}
