import server from "../../config/server";

export const SET_INVENTORIES = "SET_INVENTORIES";
export const SET_LOADING_INVENTORIES = "SET_LOADING_INVENTORIES";
export const DELETE_INVENTORY = "DELETE_INVENTORY";
export const UPDATE_INVENTORY = "UPDATE_INVENTORY";
export const FIND_INVENTORY_BY_BARCODE_LOADING =
  "FIND_INVENTORY_BY_BARCODE_LOADING";
export const UPDATE_LIST_INVENTORIES = "UPDATE_LIST_INVENTORY";

export function getInventories(
  LocationId = "",
  sortBy = "createdAt",
  order = "DESC",
  status = "",
  recieved_date = ""
) {
  return (dispatch) => {
    dispatch({
      type: SET_LOADING_INVENTORIES,
      payload: true,
    });
    return server({
      method: "get",
      url: `/stocks?LocationId=${LocationId}&sortBy=${sortBy}&order=${order}&status=${status}&recieved_date=${recieved_date}`,
      headers: {
        access_token: localStorage.getItem("BAS_access_token"),
      },
    })
      .then(({ data }) => {
        dispatch({
          type: SET_INVENTORIES,
          payload: data,
        });
        dispatch({
          type: SET_LOADING_INVENTORIES,
          payload: false,
        });
      })
      .catch(({ response }) => {
        console.log(response.data.message);
        dispatch({
          type: SET_LOADING_INVENTORIES,
          payload: false,
        });
      });
  };
}

export function addInventory(payload) {
  return (dispatch) => {
    return server({
      method: "post",
      url: "/stocks",
      headers: {
        access_token: localStorage.getItem("BAS_access_token"),
      },
      data: payload,
    });
  };
}

export function editInventory(payload) {
  return (dispatch) => {
    return server({
      method: "put",
      url: `/stocks/${payload.id}`,
      headers: {
        access_token: localStorage.getItem("BAS_access_token"),
      },
      data: payload,
    });
  };
}

export function moveInventory(payload) {
  return (dispatch) => {
    return server({
      method: "put",
      url: "/stocks/move",
      headers: {
        access_token: localStorage.getItem("BAS_access_token"),
      },
      data: payload,
    });
  };
}

export function deleteInventory(id) {
  return (dispatch) => {
    return server({
      method: "delete",
      url: `/stocks/${id}`,
      headers: {
        access_token: localStorage.getItem("BAS_access_token"),
      },
    });
  };
}

export function getInventoryByBarcode(payload) {
  return (dispatch) => {
    return server({
      method: "post",
      url: "/stocks/barcode",
      headers: {
        access_token: localStorage.getItem("BAS_access_token"),
      },
      data: payload,
    });
  };
}

export function addBarcode(payload) {
  return (dispatch) => {
    return server({
      method: "post",
      url: "/stocks/add_barcode",
      headers: {
        access_token: localStorage.getItem("BAS_access_token"),
      },
      data: payload,
    });
  };
}
